import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong, faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import "swiper/css";
import "swiper/css/navigation";

import locate0 from "../../../src/img/newimg/camel back mussoorie.webp";
import locate11 from "../../../src/img/newimg/kempty fall mussoorie.webp";
import locate22 from "../../../src/img/newimg/Lal Tibba mussoorie.webp";
import whitevideo from "../../img/newimg/whitevideo.mp4";

const NextArrow = () => (
  <button className="custom-arrow custom-next" aria-label="Next Slide">
    <FontAwesomeIcon icon={faArrowRightLong} />
  </button>
);

const PrevArrow = () => (
  <button className="custom-arrow custom-prev" aria-label="Previous Slide">
    <FontAwesomeIcon icon={faArrowLeftLong} />
  </button>
);

function ExploreSlider() {
  const sliderItems = [
    { img: locate0, title: "Camel’s Back  Road" },
    { img: locate11, title: "Kempty Falls" },
    { img: locate22, title: "Lal Tibba" },
    { img: locate0, title: "Camel’s Back  Road" },
    { img: locate11, title: "Kempty Falls" },
    { img: locate22, title: "Lal Tibba" },
  ];

  return (
    <section className="whitevideo">
      <div className="container">
        <div className="box findError">
          <h2>
            Explore nearby attractions
          </h2>
          <p>
            Discover scenic views and cultural landmarks just around the corner.
          </p>
        </div>
        <div className="row">
          <Swiper
            spaceBetween={10}
            slidesPerView={3}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            loop={true}
            navigation={{
              nextEl: ".custom-next",
              prevEl: ".custom-prev",
            }}
            modules={[Autoplay, Navigation]}
            breakpoints={{
              1400: { slidesPerView: 3 },
              1200: { slidesPerView: 3 },
              1024: { slidesPerView: 3 },
              768: { slidesPerView: 2 },
              480: { slidesPerView: 2 },
              320: { slidesPerView: 1 },
            }}
          >
            {sliderItems.map((item, index) => (
              <SwiperSlide key={index}>
                <div className="custom-slider-items text-center">
                  <img
                    src={item.img}
                    alt={item.title}
                    className="custom-slider-img-11"
                  />
                  <div className="custom-slider-text-1">
                    <h3 className="custom-slider-title-new">{item.title}</h3>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>


          <div className="custom-navigation000">
            <div className="custom-prev">
              <PrevArrow />
            </div>
            <div className="custom-next">
              <NextArrow />
            </div>
          </div>
        </div>
      </div>
      <video className="TryVideo" src={whitevideo} autoPlay loop muted />
    </section>
  );
}

export default ExploreSlider;

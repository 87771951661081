import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import locate from "../../../src/img/newimg/new11.png";
import locate1 from "../../../src/img/newimg/223.png";
import locate2 from "../../../src/img/newimg/01.png";
import locate3 from "../../../src/img/newimg/juice.png";
import locate4 from "../../../src/img/newimg/123.png";
import locate5 from "../../../src/img/newimg/Layer.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong, faArrowRightLong } from "@fortawesome/free-solid-svg-icons";

export default function SimpleSlider() {
  return (
    <section className="ourameneites">
      <div className="container">
        <div className="slider-container">
          <div className="box">
            <h2>Our   Signature  Amenities</h2>
            <p>Discover more than the basics</p>
          </div>


          <Swiper
            modules={[Navigation, Autoplay]}
            navigation={{
              nextEl: ".custom-next",
              prevEl: ".custom-prev",
            }}
            loop={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: true,
            }}
            spaceBetween={20}
            breakpoints={{
              1400: { slidesPerView: 4 },
              1200: { slidesPerView: 4 },
              1024: { slidesPerView: 4 },
              768: { slidesPerView: 2 },
              480: { slidesPerView: 2 },
            }}
          >
            <SwiperSlide>
              <div className="slide-content heightnew1">
                <img src={locate} alt="Cozy Fireplace Evenings" className="newimgg1" />
                <h3>Cozy <br /> Fireplace <br /> Evenings</h3>
                <p>
                  Experience enchanting evenings basking in the warmth and comfort
                  of cozy fireplaces.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slide-content new-ccnt">
                <img src={locate1} alt="Private Chef Dining" className="newimgg1" />
                <h3 className="small11">Private Chef <br /> Dining</h3>
                <p>
                  Create memorable dining experiences as our talented chefs cater to
                  your culinary desires.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slide-content new-ccnt">
                <img src={locate2} alt="Free High-Speed Internet" className="newimgg" />
                <h3 className="small11">Free High-Speed <br /> Internet</h3>
                <p>
                  Enjoy access to high-speed internet and stay connected with the
                  digital world throughout your stay.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slide-content heightnew1">
                <img src={locate3} alt="Daily Housekeeping" className="newimgg1" />
                <h3>Daily <br /> Housekeeping</h3>
                <p>
                  Let us ensure your comfort and convenience with impeccable
                  standards of cleanliness.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slide-content new-ccnt">
                <img src={locate4} alt="Gourmet breakfast" className="newimgg1" />
                <h3 className="small11">Gourmet breakfast <br /> delights</h3>
                <p>
                  Wake up to delight every morning as you indulge in our scrumptious breakfast offerings.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slide-content new-ccnt">
                <img src={locate5} alt="Barbecue provision" className="newimgg" />
                <h3 className="small11">Barbecue <br /> provision</h3>
                <p>
                  Savour the flavours of outdoor cooking and enjoy delicious barbecue nights amid picturesque backdrops.
                </p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="custom-navigation0">
          <button className=" custom-prev" aria-label="Previous Slide">
            <FontAwesomeIcon icon={faArrowLeftLong} />
          </button>
          <button className="custom-next" aria-label="Next Slide">
            <FontAwesomeIcon icon={faArrowRightLong} />
          </button>
        </div>

      </div>
    </section>
  );
}

import React from "react";
import loghouse from "../../img/newimg/loghouse.webp";
import horizonvilla from "../../img/newimg/layer123.png";
import TheSilvertonChalet from "../../img/TheSilvertonChalet.webp";
import { Link } from "react-router-dom";
import sicottage from "../../img/sicottage.webp";
import Review2slider from "../newsite/Review2slider";

const Stayssections = () => {
  return (
    <div>
      <section className="Stayssections">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="homely">
                <h2>Our handpicked properties </h2>
                <p className="tranquility">
                  Explore our finest collection of lavish villas, charming
                  cottages and luxury homes.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="row" id="propty">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12">
              <div className="propertybox1">
                <img src={loghouse} className="loghouse" alt="" />
                <div className="propertybox1-content">
                  <Link to={`/hotels/the-log-house`}>
                    <h2 className="text-white">The Log House</h2>
                  </Link>
                  <ul>
                    <li>2 Bedrooms </li>
                    <li>Modernized Kitchen</li>
                    <li>Living Room</li>
                    <li>Bonfire Pit </li>
                  </ul>
                  <ul>
                    <li>Barbecue Area</li>
                    <li>WiFi/TV</li>
                    <li>Pet-Friendly </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12">
              <div className="propertybox1">
                <img src={horizonvilla} className="loghouse" alt="" />
                <div className="propertybox1-content">
                  <Link to={`/hotels/the-horizon-villa`}>
                    <h2 className="text-white">The Horizon Villa</h2>
                  </Link>
                  <ul>
                    <li>4 Bedrooms </li>
                    <li>Trained Chef</li>
                    <li>Wi-Fi/TV </li>
                    <li>Air Conditioning</li>
                  </ul>
                  <ul>
                    <li>Workspace</li>
                    <li>Patio or Balcony </li>
                    <li>Bonfire/BBQ </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row" id="propty2">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12">
              <div className="propertybox1">
                <img src={TheSilvertonChalet} className="loghouse" alt="" />
                <div className="propertybox1-content">
                  <Link to={`/hotels/the-silverton-chalet`}>
                    <h2 className="text-white">The Silverton Chalet</h2>
                  </Link>
                  <ul>
                    <li>3 Bedrooms</li>
                    <li>1 Duplex Room </li>
                    <li>3 Sit Out Areas</li>
                    <li>Skilled Chef</li>
                  </ul>
                  <ul>
                    <li>2 Housekeepers</li>
                    <li>Private Kitchen</li>
                    <li>4 Smart TVs</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12">
              <div className="propertybox1">
                <img src={sicottage} className="loghouse" alt="" />
                <div className="propertybox1-content">
                  <Link to={`/hotels/the-si-cottage`}>
                    <h2 className="text-white">The SI Cottage</h2>{" "}
                  </Link>
                  <ul>
                    <li>3 Bedrooms</li>
                    <li>Garden</li>
                    <li>Living Room </li>
                    <li>Open Air Deck</li>
                  </ul>
                  <ul>
                    <li>Secured Parking </li>
                    <li>Indoor Fireplace </li>
                    <li>Inverter/Heater</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row" id="propty2">
            {/* <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12">
              <div className="propertybox1">
                <img src={minihorizon} className="loghouse" alt="" />
                <div className="propertybox1-content">
                  <Link to={`/hotels/the-mini-horizon`}>
                    <h2 className="text-white">The Mini Horizon</h2>
                  </Link>
                  <ul>
                    <li>1 Bedroom</li>
                    <li>Private Bathroom</li>
                    <li>Scenic Balcony </li>
                    <li>Wi-Fi/TV</li>
                  </ul>
                  <ul>
                    <li>Modernized Kitchen</li>
                    <li>Free Parking </li>
                    <li>Living Lounge </li>
                  </ul>
                </div>
              </div>
              </div> */}
            {/* <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
                        <div className='propertybox1'>
                          <img src={Pinkmangolia} className='loghouse' alt='' />
                          <div className='propertybox1-content'>
                            <Link to={`/hotels/the-pink-magnolia-cottage`}>
                              <h2 className='text-white'>The Pink Magnolia</h2>
                            </Link>
                            <ul>
                              <li>4 Bedrooms</li>
                              <li>Private Terrace</li>
                              <li>Dining Area </li>
                              <li>Housekeeping</li>
                            </ul>
                            <ul>
                              <li>Sundeck</li>
                              <li>Convenient Parking </li>
                              <li>Wi-Fi/TV</li>
                            </ul>
                          </div>
                        </div>

                      </div> */}
          </div>
          <div className="row" id="propty2">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12">
              {/* <div className='propertybox1'>
              <img src={sicottage} className='loghouse' alt='' />
              <div className='propertybox1-content'>
                <Link to={`/Rooms/#sicottage`}>
                  <h2 className='text-white'>The SI Cottage</h2>  </Link>
                <ul>
                  <li>3 Bedrooms</li>
                  <li>Garden</li>
                  <li>Living Room </li>
                  <li>Open Air Deck</li>
                </ul>
                <ul>
                  <li>Secured Parking </li>
                  <li>Indoor Fireplace </li>
                  <li>Inverter/Heater</li>
                </ul>
              </div>
            </div> */}
            </div>
          </div>
        </div>
      </section>

      {/* <section className="client-reviews">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-md-12">
              <h5 className="new-sub-heading">
                Delighted words that keep our wings soaring
              </h5>
              <h2 className="new-heading">Rave reviews </h2>
            </div>
          </div>
        </div>
      </section> */}
      {/* <Testimonials /> */}
      <Review2slider />

      {/* <Instapost /> */}
      <section className="lastcta">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-12 col-md-12 col-12">
              <div className="outboxer">
                <h5 className="new-sub-heading">Book your getaway now! </h5>
                <h3 className="new-heading-2">
                  Let us be your host as you create cherished memories away
                  from the hustle and bustle. Book your stay with us and open
                  doors to a world of luxury, comfort and heartfelt hospitality.{" "}
                </h3>
                <div className="Proding2 mt-4">
                    <a href="/Register" className="explore-button1">
                    SIGN UP <span className="arrow1">→</span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Stayssections;

import React from "react";
import blacklog1 from "../../../src/img/blacklog1.png";
import { FaFacebookF, FaTwitter, FaInstagram } from "react-icons/fa";
import "./footer3.css";
import { AuthContext } from "../../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useContext, useState } from "react";

const Footer3 = () => {
  const { loading, error, dispatch } = useContext(AuthContext);
  const axiosinstance = axios.create({ baseURL: process.env.API_URL });
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({
    email: "",
  });
  const [alertMessage, setAlertMessage] = useState("");
  
  const handleChange = (e) => {
    setCredentials({
        ...credentials,
        [e.target.name]: e.target.value,
    });
};

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch({ type: "LOGIN_START" });
    try {
      const res = await axiosinstance.post(
        "https://www.api.somanymornings.com/api/auth/subscribe",
        credentials
      );
      // Store some data in localStorage
      alert("You have successfully subscribed to our newsletter.");
      // setAlertMessage("Form submitted successfully!");
      setCredentials({ email: "" });
      setTimeout(() => {
        navigate("/");
      }, 1500);
      
      // dispatch({ type: "LOGIN_SUCCESS", payload: res.details.data });
      // Clear localStorage after successful form submission
    } catch (err) {
      dispatch({ type: "LOGIN_FAILURE", payload: err.response.data });
      setAlertMessage("Form submission failed. Please try again later.");
    }
  };
  return (
    <>
      <section className="new footer3">
        <div className="container">
          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
            <div className="row">
              <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12">
                <a href="/">
                <img src={blacklog1} alt="" className="blacklog22" /></a>

                <div className="footerinfo1">
                  <p>
                    <span className="text-white1">
                      <strong>Email: </strong>
                    </span>
                    <a
                      className="text-white2"
                      href="mailto:bookings@somanymornings.com"
                    >
                      bookings@somanymornings.com
                    </a>
                  </p>
                  <p>
                    <span className=" text-white1">
                      <strong>Tel: </strong>
                    </span>
                    <a className="text-white2" href="tel:+91-9878908399">
                      +91-9878908399
                    </a>
                  </p>
                </div>
              </div>

              <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-6 col-12">
                <ul className="Mangolia-list1">
                  <li>
                    <a href="/hotels/the-horizon-villa">The Horizon Villa</a>
                  </li>
                  <li>
                    <a href="/hotels/the-log-house">The Log House</a>
                  </li>
                  <li>
                    <a href="/hotels/the-silverton-chalet">
                      The Silverton Chalet
                    </a>
                  </li>
                  <li>
                    <a href="/hotels/the-si-cottage">The SI Cottage</a>
                  </li>
                  <li>
                    <a href="/villa-collections">Villa Collections</a>
                  </li>
                </ul>
              </div>

              <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-6 col-12">
                <ul className="Mangolia-list1">
                  <li>
                    <a href="/About">About</a>
                  </li>
                  <li>
                    <a href="/book-your-stay">Book Your Stay</a>
                  </li>
                  <li>
                    <a href="/special-events">Special Events</a>
                  </li>
                  <li>
                    <a href="/contact">Contact</a>
                  </li>
                  <li>
                    <a href="/Offers">Offers</a>
                  </li>
                </ul>
              </div>

              <div className="col-xxl-5 col-xl-5 col-lg-4 col-md-6 col-12">
                <div className="new-list1">
                  <div className="new-list">
                    <ul className="Mangolia-list1">
                      <li>
                        <a href="/">Refund Policy</a>
                      </li>
                      <li>
                        <a href="/privacy-policy">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="/Cancellation-policy">Cancellation Policy</a>
                      </li>
                      <li>
                        <a href="/terms-conditions">Terms & Conditions</a>
                      </li>
                    </ul>

                    <div className="social-icons">
                      <a
                        href="https://www.facebook.com/profile.php?id=61555369394552"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="icon1"
                      >
                        <FaFacebookF />
                      </a>
                      <a
                        href="/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="icon1"
                      >
                        <FaTwitter />
                      </a>
                      <a
                        href="https://www.instagram.com/somanymornings_/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="icon1"
                      >
                        <FaInstagram />
                      </a>
                    </div>
                  </div>

                  <div className="subscribe-container">
                    <h6>Subscribe for Newsletter</h6>
                    <form className="subscribe-form"
                    data-wow-delay="0.12s"
                    data-wow-duration="1s"
                    style={{
                      visibility: "visible",
                      animationDuration: "1s",
                      animationDelay: "0.12s",
                      animationName: "fadeInUp",
                    }}
                    onSubmit={handleSubmit}>
                      <input
                        className="email-input"
                        name="email"
                        placeholder="Enter Your Email Address"
                        type="email"
                        id="email"
                        value={credentials.email}
                        onChange={handleChange}
                        required
                      />
                      <button className="subscribe-button" type="submit">Subscribe</button>
                    </form>
                    {alertMessage && <div className="alert">{alertMessage}</div>}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                <div className="copyright-right1">
                  <p className="copyright-text1">
                    © Copyright somanymornings 2024 All rights reserved
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer3;

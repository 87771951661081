import Footer2 from "../../components/footer/Footer2";
import Aboutbanner from "../../components/Homebanner/Aboutbanner";
import family from "../../img/family.png";
import leaves from "../../img/leaves.png";
import homestay from "../../img/homestay.png";
import Testimonials from "../../components/newsite/testimonials";
import Navbar2 from "../../components/navbar/Navbar2";
import Instapost from "../../components/newsite/instapost";
import rightpick2 from "../../img/facilities-pics/rightpick2.png";
import Newheader from "../../components/navbar/Newheader";
import Review2slider from "../../components/newsite/Review2slider";

const About = () => {
  return (
    <div>
      {/* <TransparentNavbar /> */}
      {/* <Homeheader /> */}
      {/* <Navbar /> */}
      <Aboutbanner />
      <section className="Aboutsections1">
        <div className="container">
          <div className="row" id="abtrow3">
            <div className="col-xxl-6-col-xl-6 col-lg-6 col-md-12 col-12">
              <div className="colla-abt">
                <div className="style_new sc-heading article_heading ">
                  <div className="content-heading">
                    <h2 className="new-heading"> What we’re about</h2>
                    <div className="description-heading">
                      <p className="Coast">
                        So Many Mornings open doors to the finest holiday homes
                        and villas, each boasting magnificent views, gorgeous
                        aesthetics and a home-like vibe. We endeavour to make
                        your stay an unforgettable experience filled with the
                        warmth and comforts of a home away from home. Our
                        handpicked villa collection provides guests with a
                        one-of-its-kind experience, coupling luxury with
                        impeccable service and top-notch amenities. Whether
                        you’re looking for a romantic escape, a family getaway
                        or a solo adventure, we have something to cater to every
                        taste and preference. So, are you ready to whisk away
                        for an extraordinary staycation with us?
                      </p>
                    </div>
                  </div>
                  <div className="background-heading">About</div>
                  <div className="button-heading">
                    <a href="/contact">Contact us</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12 just">
              <img src={rightpick2} className="rightpick" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="commitment">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="exciting">
                <h5 className="new-sub-heading">Why choose us </h5>
                <h2 className="new-heading">
                  Because we provide experiences <br />
                  curated just for you!
                </h2>
              </div>
            </div>
          </div>
          <div className="row" id="adventures">
            <div className="col-xxl-4 col-xl-4 col-md-4 col-12">
              <div className="whychoosebox">
                <img src={leaves} className="leaves" alt="" />
                <h3 className="livee">World-class amenities</h3>
                <p>
                  Experience a memorable flavour of comfort infused with modern
                  amenities and facilities.
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-md-4 col-12">
              <div className="whychoosebox1">
                <img src={homestay} className="leaves" alt="" />
                <h3 className="livee">Personalized service</h3>
                <p>
                  Enjoy a staycation where every aspect of the stay is tailored
                  to your unique needs and preferences.{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-md-4 col-12">
              <div className="whychoosebox2">
                <img src={family} className="leaves" alt="" />
                <h3 className="livee">Home-like ambiance</h3>
                <p>
                  We provide genuine hospitality, ensuring a homely ambiance
                  where every guest is treated like a family.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="aboutcta">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-md-12">
              <h5 className="new-sub-heading">Step into luxury living </h5>
              <h2 className="cap-heading">
                So Many Mornings offers a curated collection of premium
                accommodations nestled amid magnificent views that evoke a sense
                of luxury and timeless aesthetics.{" "}
              </h2>
              <div className="timeless">
                <a className="luxury" href="/villa-collections">
                  EXPLORE NOW
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="client-reviews">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-md-12">
              <h5 className="new-sub-heading">
                Delighted words that keep our wings soaring
              </h5>
              <h2 className="new-heading">Rave reviews </h2>
            </div>
          </div>
        </div>
      </section> */}
      {/* <Testimonials />
      <Instapost /> */}
      <Review2slider />
      <section className="lastcta">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-12 col-md-12 col-12">
              <div className="outboxer">
                <h5 className="new-sub-heading">Book your getaway now! </h5>
                <h3 className="new-heading-2">
                  Let us be your host as you create cherished memories away
                  from the hustle and bustle. Book your stay with us and open
                  doors to a world of luxury, comfort and heartfelt hospitality.{" "}
                </h3>
                  <div className="Proding2 mt-4">
                    <a href="/Register" className="explore-button1">
                    SIGN UP <span className="arrow1">→</span>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
